import { createItem, updateItem } from '@/api/common';
import { deepClone } from '@/utils/index';

/* eslint-disable func-names */
// eslint-disable-next-line no-extend-native
Function.prototype.clone = function () {
  const that = this;
  // eslint-disable-next-line prefer-rest-params
  const temp = function temporary() {
    return that.apply(this, arguments);
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const key in this) {
    // eslint-disable-next-line no-prototype-builtins
    if (this.hasOwnProperty(key)) {
      temp[key] = this[key];
    }
  }
  return temp;
};
export default {
  name: 'EditDialog',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    object: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 600
    },
    formFields: {
      type: Object,
      default: () => ({})
    },
    formRules: {
      type: Object,
      default: () => ({})
    },
    endpoint: {
      type: String,
      default: null
    },
    canConfirm: {
      type: Function,
      required: true
    },
    extraFormData: {
      type: Object,
      default: null
    },
    labelPosition: {
      type: String,
      default: 'top'
    },
    formatRequestData: {
      type: Function,
      default: null
    },
    afterHandleOpen: {
      type: Function,
      default: null
    },
    skipFormValidation: {
      type: Boolean,
      default: false
    },
    updateEndpoint: {
      type: String,
      default: null
    },
    dialogCustomClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        ...this.formFields
      },
      rules: {
        ...this.formRules
      },
      loading: false
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  created() {
    Object.keys(this.rules).forEach(k => {
      this.rules[k].forEach(r => {
        if (r.validator) {
          const method = r.validator.clone();
          r.validator = (rule, value, callback) => {
            method(this, rule, value, callback);
          };
        }
      });
    });
  },
  methods: {
    handleOpen() {
      if (this.object != null) {
        Object.keys(this.form).forEach(k => {
          this.form[k] = this.object[k];
        });
      } else {
        this.form = deepClone(this.formFields);
      }
      if (this.afterHandleOpen) {
        this.form = this.afterHandleOpen(this.form);
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
    },
    handleClose() {
      this.$emit('close');
      this.dialogVisible = false;
      this.form = {
        ...this.formFields
      };
      this.loading = false;
      this.$refs.form.clearValidate();
    },
    handleConfirm() {
      if (this.skipFormValidation) {
        this.submit();
      } else {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submit();
          }
        });
      }
    },
    submit() {
      if (this.endpoint == null) {
        this.$emit('confirm', {
          ...this.form
        });
        this.handleClose();
        return;
      }
      this.loading = true;
      let requestData = {
        ...this.form
      };
      if (this.extraFormData) {
        requestData = {
          ...requestData,
          ...this.extraFormData
        };
      }
      if (this.formatRequestData) {
        requestData = this.formatRequestData(requestData);
      }
      if (this.object === null || this.object.id == null) {
        createItem(this.endpoint, requestData).then(res => {
          this.loading = false;
          this.$emit('confirm');
          this.handleClose();
          if (res.code === 0) {
            this.$message({
              message: this.$t('common.operation_success'),
              type: 'success'
            });
          }
        });
      } else {
        const endpoint = this.updateEndpoint || this.endpoint;
        const id = this.updateEndpoint == null ? this.object.id : null;
        updateItem(endpoint, id, requestData).then(res => {
          this.loading = false;
          this.$emit('confirm');
          this.handleClose();
          if (res.code === 0) {
            this.$message({
              message: this.$t('common.operation_success'),
              type: 'success'
            });
          }
        });
      }
    }
  }
};