import { render, staticRenderFns } from "./EditDialog.vue?vue&type=template&id=47d57bc2&"
import script from "./EditDialog.vue?vue&type=script&lang=js&"
export * from "./EditDialog.vue?vue&type=script&lang=js&"
import style0 from "./EditDialog.vue?vue&type=style&index=0&id=47d57bc2&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47d57bc2')) {
      api.createRecord('47d57bc2', component.options)
    } else {
      api.reload('47d57bc2', component.options)
    }
    module.hot.accept("./EditDialog.vue?vue&type=template&id=47d57bc2&", function () {
      api.rerender('47d57bc2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/templates/EditDialog.vue"
export default component.exports